.container-reseller-user-create-screen{
    min-height: 85vh;
    margin: 25px;
}

.container-reseller-user-create-screen-col{
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel-container-reseller-user-create-screen{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}