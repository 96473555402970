.my-container {
    padding: 0;
} 
.plate-number-button {
    padding: 15px;
    border-radius: 4px;
    background-color: #f2f3f5;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    -webkit-box-shadow: -2px 3px 7px 0px rgba(23, 75, 136, 0.13);
    -moz-box-shadow: -2px 3px 7px 0px rgba(23, 75, 136, 0.13);
    box-shadow: -2px 3px 7px 0px rgba(23, 75, 136, 0.13);
}

.plate-number-button:hover {
    background-color: #174c88;
    color: white
}

.plate-number-button-selected {
    background-color: #174c88;
    color: white
}

.plate-number-closebtn {
cursor: pointer
}

.circular-button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #02afff;
    color: white;
    width: 25px;
    height: 25px;
    border-radius: 100%
}

.platenumber-modal-button-cancel {
    border-radius: 5px;
    font-size: 15px;
    margin-top: 15px;
    padding: 5px 8px 5px 8px;
    background-color: #eb242e;
    border: none;
    color: white;
    font-weight: bold;
}

.platenumber-modal-button-cancel:hover {
    background-color: #eb242e;
    color: white;
    text-decoration: none;
}

.platenumber-modal-button-cancel:focus{
    background-color: #eb242e;
    color: white;
    text-decoration: none;
}
