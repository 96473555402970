.panel-reseller-report{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.btn-download-report-reseller{
    background: hsl(235, 100%, 32%);
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
}

.btn-download-report-reseller :hover{
    background: hsl(193, 97%, 49%)
}

.span-btn-download-report-reseller{
    display: block;
    padding: 12px 42px 8px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: rgb(0 40 255);
    color: white;
    transform: translateY(-6px);
    transition: all 0.15s linear;
}

.btn-download-report-reseller:active .span-btn-download-report-reseller{
    transform: translateY(-2px);
}


/*LAPTOP M*/
@media screen and (min-width: 992px) and (max-width: 1024px) {
    .btn-download-report-reseller-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .btn-download-report-reseller{
        width: 240px;
    }
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 768px) {
    .btn-download-report-reseller-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn-download-report-reseller{
        width: 240px;
        margin-top: 20px;
    }
}

/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    .panel-reseller-report{
        margin: 25px;
    }

    .btn-download-report-reseller-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn-download-report-reseller{
        width: 240px;
        margin-top: 20px;
    }
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {
    .panel-reseller-report{
        margin: 25px;
    }

    .btn-download-report-reseller-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn-download-report-reseller{
        width: 200px;
        margin-top: 20px;
    }
}