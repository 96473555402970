
.inputLogin {
    width: 100%;
    margin-bottom: 1.4rem;
    padding: 1rem;
    background-color: #f6f6f6;
    border-radius: 0.2rem;
    border: none;
}
input:focus {
    outline: none !important;
}

.login-title{
    color: #174c88;
    text-align: center;
    border-bottom: 1px solid #dddddd;
    padding: 8px;
    margin-bottom: 3px;
}

.spid-button-icon {
    height: 2rem;
    margin-left: 1rem;
    margin-right: 0rem;
}

.icon-question-spid {
    font-size: 1.5rem;
    margin-bottom: 0rem;    
    margin-right: 0rem;
    margin-left: 0.5rem;
    padding-top: 1.2rem;
    color: white;
    background-color: #174c88;
    border-radius: 5px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.spid-agid-icon {
    padding-left: 2rem;
    height: 2.8rem;
}

.cie-minint-icon {
    height: 2.8rem;
}

.login-admin-logo {
    width: 13em;
    margin-bottom: 2.5em;
}

.login-input-box{
    margin-bottom: 0.8em;
    margin-top: 1.8em;
}
.panel-border-login {
    border-top: 10px solid #174c88;
}

.pas >a {
    color: #174c88;
    text-decoration: none !important;
}

.error {
    color: #ee0000;
    font: bold;
}