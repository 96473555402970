.header-nav-color-1{
    width: 100%;
    border: solid transparent 0px;
    margin-top: 1px;
    margin-bottom: 1px;
}
.header-nav-color-2{
    width: 100%;
    border: solid transparent 0px;

}
.header-nav-color-3{
    width: 100%;
    border: solid transparent 0px;
    margin-top: 1px;
    margin-bottom: 20px;
}