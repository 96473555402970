.btn-add{
    border-radius:0px;
    background-color: #dddddd;
    border:none;
    padding-right: 1em;
    padding-left: 1em;
    margin-left: 10px;
    margin-right: 10px;
}

.react-dropdown-tree-select .tree input {
    display: none
}

.react-dropdown-tree-select .tree i {
    pointer-events: all;
}

.react-dropdown-tree-select .tree label {
    pointer-events: none
}

.ReactTable .rt-tbody {
    overflow-x: hidden!important;
}