.navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #101010;
}
.navbar-default .navbar-toggle {
    border: none;
    color: white;
}

.clear-link {
    text-decoration: none !important;
    color: white !important;
    border: 0 !important;
    background: none !important;
    padding: 10px 15px !important;
}

.navbar-btn:active{
    background-color: transparent!important;
}
.navbar-btn:hover{
    background-color: transparent!important;
}
.navbar-btn >.icon-bar{
    background-color: white !important;
}

.mobile-nav-link {
    max-width: 300px;
    margin: 10px 0;
}
.mobile-nav-link:hover {
    transform: scale(1);
}
.menuPosition{
    display: flex;
    align-items: center;
    justify-content: space-between;
}