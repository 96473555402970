.margin-btn-p{
    margin-top: 1.5em;
}

.btnSubmit{
    margin-right: .5em !important;
    margin-left: -.1em !important;
    margin-top: 1em;
    background-color: #174c88;
    border-color: #192fbf;
    color: white;
    cursor: pointer;
    transition: all 0.15s linear;
    font: caption;
    padding: 4px;
    padding-left: .5em;
    padding-right: .5em;
    border-radius: .5em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
}
.btnSubmit:hover,.btnSubmit:active,.btnSubmit:focus,.btnSubmit:active:focus, .btnSubmit:active:hover {
    background-color: #02afff;
    color: white;
    border-radius: .8em;
}

.btnBack{
    margin-right: .5em !important;
    margin-left: -.1em !important;
    margin-top: 1em;
    background-color: #d9534f;
    border-color: #d43f3a;
    cursor: pointer;
    transition: all 0.15s linear;
    color: white;
    cursor: pointer;
    transition: all 0.15s linear;
    font: caption;
    padding: 4px;
    padding-left: .5em;
    padding-right: .5em;
    border-radius: .5em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
}


.btnBack:hover, .btnBack:focus, .btnBack:active, .btnBack:active:focus, .btnBack:active:hover {
    background-color: #ff5f02;
    color: white;
    border-radius: .8em;
}