.registration-title{
    font-size: 1.5rem;
    font-weight: bold;
    color: #174c88;
}

.registration-title-2{
    font-size: 1.5rem;
    font-weight: bold;
    color: #848484;
}


.registration-subtitle{
    font-size: 1.1rem;
}
