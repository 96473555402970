.glyphicon-lg
{
    font-size:4em
}

.userDetailModal h1, .userDetailModal h2, .userDetailModal h3, .userDetailModal h4, .userDetailModal h5{
    margin-bottom: 10px;
}

.user-data {
    margin-left:16px;
}

h3.title {
    color: #174c88;
    margin-top: 0;
    padding-bottom: 10px;
}

/* LAPTOP M VER COMO QUE NO MOBILE E LAPTOP FICA CENTRADO ENQUANTO NO LAPTOP FICA TORTO */
@media screen and (min-width: 992px) and (max-width: 1024px) {
    
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 991px) {
    
}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {
    
}