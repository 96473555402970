footer {
    color: #848484;    
}
footer .footer-above {
    padding-top: 0.8em;
    padding-bottom: 0.1em;
    background-color: #f2f1ef;
}

footer .logo-message-logo{
    max-width: 0.3em;
    margin-top: 0.12em;
}

.footer-logo-col{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.try {
    height: 130px;
    transition: all 0.2s linear;
}
.try:hover{
    transform: scale(1.1);
}

.logoT, .logoC {
    max-height: 90px;
    margin: 20px 0;
}

.footer-popper {
    position: absolute;
    right: 0;
    top: 30px;
    font-weight: 700;
    opacity: 0;
    transition: all 300ms ease;
    color: black;
}

.footer-anchor {
    margin-bottom: 20px;
    position: relative;
}

.footer-anchor:hover .footer-popper {
    transform: translateX(10px);
    opacity: 1;
}


/*LAPTOP*/
@media screen and (max-width: 990px) {
    .logo {
        max-width: 200px;
        padding-top: 14px;
    }
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 768px) {

    .logoT{
        max-height: 80px;
    }

    .footer-logo-content{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logos-fa-footer{
        display: flex;
        justify-content: flex-end;
    }

    .try {
        height: 100px !important;
    }
    .logoT{
        max-height: 40px;
        max-width: 110px;
    }
    .logoC{
        max-height: 40px;
        max-width: 110px;
    }
    .res-mob{
        display: flex;
        justify-content: center;
    }
    .sectionIcons{
        max-height: 30px;
        max-width: 60px;
    }
    .logo-input-footer{
        max-width: 10px;
        max-height: 20px;
    }
}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {

    .comune-text{
        display: none;
    }

    .logoT{
        max-height: 80px;
    }

    .footer-logo-content{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logos-fa-footer{
        display: flex;
        justify-content: flex-end;
    }

    .try {
        height: 100px !important;
    }
    .logoT{
        max-height: 40px;
        max-width: 110px;
    }
    .logoC{
        max-height: 40px;
        max-width: 110px;
    }
    .res-mob{
        display: flex;
        justify-content: center;
    }
    .sectionIcons{
        max-height: 30px;
        max-width: 60px;
    }
    .logo-input-footer{
        max-width: 10px;
        max-height: 20px;
    }
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {

    .footer-logo-content{
        margin-left: 2px;
    }

    .comune-text{
        display: none;
    }

    .logoT{
        max-height: 80px;
    }

    .footer-logo-content{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logos-fa-footer{
        display: flex;
        justify-content: flex-end;
    }

    .try {
        height: 100px !important;
    }
    .logoT{
        max-height: 40px;
        max-width: 110px;
    }
    .logoC{
        max-height: 40px;
        max-width: 110px;
    }
    .res-mob{
        display: flex;
        justify-content: center;
    }
    .sectionIcons{
        max-height: 30px;
        max-width: 60px;
    }
    .logo-input-footer{
        max-width: 10px;
        max-height: 20px;
    }
}


