.fixedSize300 {
    max-width: 300px;
}

.monthField{
    width: 100%;
    height: 50px;
    max-width: 300px;
    margin-bottom: 1.4rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.2rem;
    border: solid;
    border-width: 1px;
    border-color: #cccccc;
}

.labelMonthField{
    margin-left: 1px;
    width: 100%;
    max-width: 300px;
    background-color: white;
    border-radius: 0.2rem;
    padding-left: 2%;
}

.modale-body-reseller.modal-body {
    padding: 1px;
}

div::-webkit-scrollbar {
    scrollbar-width: 0px;
    width: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}

.modale-reseller .modal-dialog {
    /*margin: 2px;
    padding: 2px;
    border: 1px solid transparent;*/
    margin: 0;
    position: relative;
    top: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 99.9%;
    height: 99.9%;
    border-radius: 2rem;
    /*overflow: hidden;*/
    overflow-x: hidden;
    overflow-x: hidden;
}

.modal-backdrop {
    z-index: 999;
}

.modale-reseller .modal-content {
    min-width: 80vw;
    max-width: 90vw;
    min-height: 90vh;
    max-height: 95vh;
    border-radius: 2rem;
    border: 1px solid #174c88;
    /* patch per rimuovere bordo da modale altrimenti visibile */
    border: 1px solid #174c88;
    /* patch per rimuovere bordo da modale altrimenti visibile */
}

.modale-reseller {
    min-width: 80vw;
    max-width: 90vw;
    min-height: 90vh;
    max-height: 95vh;
    border-radius: 2rem;
    z-index: 1000;
    /* Posiziona la modale sotto la finestra messaggi */
}

.modale-reseller {
    margin: 30px auto;
    /* Centra la modale */
    padding: 0;
    height: fit-content;
    background-color: #174c88;
    border-radius: 2rem;
    border: solid 5px #174c88;
}

.modal-header {
    border-bottom: 8px solid white;
}

.modale-body-reseller {
    min-width: 100%;
    max-width: 100%;
    min-height: 75%;
    max-height: 75%;
    height: 70%;
}

.header-modale-reseller {
    background-color: #174c88;
}

.custom-tabs {
    margin-top: 30px;
}

.tab-content,
.tab-pane {
    position: relative;
    width: 86vw;
    height: 71vh;
    padding-top: 1vh;
}

.stiky-tab-modale-reseller {
    position: sticky;
    bottom: 0;
    padding-right: .5vw;
    margin-left: .5vw;
}

[id^="tab-modale-reseller-pane-"],
[id*="tab-modale-reseller-pane-"] {
    min-width: 98%;
    max-width: 98%;
    min-height: 98%;
    max-height: 98%;
    height: 98%;
    width: 98%;
    margin-top: 1vh;
}

[id^="tab-modale-reseller-tab-"], [id*="tab-modale-reseller-tab-"] {
    border: 1px solid #ddd !important;
    border-radius: 15px 15px 0 0;
    margin: 10px 10px 0px 20px;
    color: #555;
    cursor: default;
    background-color: #eceaea;
    border-bottom-color: transparent;
}

.nav-tabs>li.active>a[id^="tab-modale-reseller-tab-"]:focus,
[id*="tab-modale-reseller-tab-"].nav-tabs>li.active>a:focus {
 background-color: #02afff !important;
}

.nav-tabs>li.active>a[id^="tab-modale-reseller-tab-"]:hover,
[id*="tab-modale-reseller-tab-"].nav-tabs>li.active>a:hover {
 background-color: #02eaff !important;
}

.modal-content-tab1 {
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 10px;
}

.container-modale-reseller {
    margin: 2vh 2vw;
    display: flex;
    flex-direction: column;
    padding: 2vh 2vw;

    min-width: 98%;
    max-width: 98%;
    min-height: 98%;
    max-height: 98%;
    height: 98%;
    width: 98%;
    justify-content: flex-start;
}

.row-container-modale-reseller {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-container-form-modale-reseller {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.col-container-modale-reseller {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.table-container-modale-reseller {
    height: 100%;
    width: 100%;
}

.header-canvas {
    background-color: #174c88;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    /*border-radius: 2rem;*/
    border-width: 0;
}

/* ####### START Pulsanti ####### */

.modal-header>button>span {
    color: #ed0d0d;
    background-color: transparent;
    position: relative;
    opacity: 0.85;
    transition: transform 0.15s linear, color 0.15s linear;
}

.modal-header>button>span:hover {
    position: relative;
    opacity: 1;
    transform: scale(1.2);
}

.modal-header>button:hover,
.header-modale-reseller.modal-header>button:hover,
.header-modale-reseller.modal-header>button:active,
button.close:hover,
button.close:active {
    transform: scale(1.3);
    opacity: 1;
    border-radius: 1em;
    top: 1rem;
    right: 1rem;
}

.icon-tie-modale-reseller {
    top: 0.6px;
    position: relative;
    transition: transform 0.15s linear;
    /* max transform scale */
    /*max-inline-size: 1;
    max-block-size: 1;*/
    max-height: 24px;
}

.buttons-icons-modale-reseller {
    width: auto;
}

.badge-buttons-modale-reseller {
    display: flex;
    align-items: center;
    /* Centra il contenuto verticalmente */
    justify-content: center;
    /* Centra il contenuto orizzontalmente */
    background-color: #136adf;
    color: rgba(255, 255, 255, 0.95);
    border: none;
    font: caption;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.15s linear, transform 0.15s linear, box-shadow 0.15s linear, position 0.15s linear, border-radius 0.15s linear, opacity 0.15s linear;
    padding: 15px;
    /* Imposta lo stesso padding su tutti i lati */
    margin: 5px;
    /* Aggiunge margine tra i pulsanti */
}

.buttons-icons-modale-reseller:active,
.buttons-icons-modale-reseller:hover {
    /*background-color: #0897fd;*/
    transform-origin: center;
    /*transform: scale(1.2);*/
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: rgb(206, 253, 255);*/
    /*border: 3px solid #0897fd;*/
}

.button-reseller {
    margin: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

span.button-reseller>small {
    color: #f6f6f6;
    font-size: 7.2pt;
}

.icon-wrapper {
    margin-right: -1px;
    /* Aggiungi spazio a destra per centrare l'icona */
}

.buttons-icons-modale-reseller {
    transition: transform 0.15s linear, color 0.15s linear;
}

.badge-buttons-modale-reseller:active:focus,
.badge-buttons-modale-reseller:focus,
.badge-buttons-modale-reseller:hover,
.badge-buttons-modale-reseller:active {
    transform: scale(1.2);
    color: white;
    background-color: #0095ff;
    border-color: #0897fd;
    border: 2px solid #0897fd;
}

.header-modale-reseller.modal-header>button,
button.close {

    transition: transform 0.15s cubic-bezier(0.0, 0.0, 1.0, 1.0), position 0.15s linear, border-radius 0.15s linear, opacity 0.15s linear, color 0.15s linear;
    width: 4rem;
    height: 4rem;
    color: #ed0d0d;
    text-shadow: 1px 1px 1px #fff;
    background-color: #02afff;
    border: solid 0.1rem #ffffff;
    border-radius: .35em;
    outline: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    margin: 0.7rem;
    opacity: 0.7;
}

.nav-tabs>li>a {
    margin-left: 1vw;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover,
.nav-tabs>li>a:hover,
.nav-tabs>li>a:active {
    color: white !important;
    cursor: default !important;
    background-color: #0278f6 !important;
    border: 1px solid #0278f6 !important;
    border-bottom-color: #0278f6 !important;
    border-color: #9bb9f8 #9dc4fb #9ee5fa !important;
}

#Situazione-Reseller:focus {
    background-color: #0095ff;
    color: white;
    border: 1px solid #0897fd;
}

/* ####### END Pulsanti ####### */

/*SOLO VERTICALE*/
/*TUTTI I TELEFONI MESSI IN VERTICALE*/

/* Stili per schermi con larghezza tra 360px e 900px */
@media (min-width: 360px) and (max-width: 900px) {
    .title-header-modale-reseller {
        display: grid;
        margin: 0;
    }

    .badge-buttons-modale-reseller:hover,
    .badge-buttons-modale-reseller:active {
        transform: scale(1.2);
        margin-left: auto;
        margin-right: auto;
        padding-left: 2em;
        padding-right: 2em;
        max-width: 82%;
        min-width: 82%;
    }

    .badge-buttons-modale-reseller {
        /* Imposta lo stesso padding su tutti i lati */
        padding-block-end: 2px;
        padding-block-start: 2px;
        padding-bottom: 2px;
        padding-inline-end: 2px;
        padding-inline-start: 2px;
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 2px;
        /* Aggiunge margine tra i pulsanti */
        margin: 5px;
    }

    .tab-modale-reseller>ul>li {
        font-size: 13px;
    }

    .modal-header {
        padding: 5px;
    }

    .header-modale-reseller.modal-header>button,
    button.close {
        top: -0.5rem;
        right: -0.5rem;
        z-index: 2500;
    }

    .modal-header>button:hover,
    .header-modale-reseller.modal-header>button:hover,
    .header-modale-reseller.modal-header>button:active,
    button.close:hover,
    button.close:active {
        top: 0;
        right: 0;
    }

    th:first-child,
    td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
        /* Imposta il colore di sfondo desiderato */
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
        /* Imposta il colore di sfondo desiderato */
    }

    .tab-modale-reseller>ul {
        font-size: 15px;
        margin-top: 7px;
        padding-left: 0;
        padding-right: 0;
        padding-top: 2px;
    }

    .nav-tabs>li>a {
        margin-right: 0;
        padding: 14px 7px;
        /*font-variant: all-small-caps;
        font-variant-caps: all-small-caps;*/
    }

    .container-reseller {
        font-size: 10px;
    }

    .stiky-tab-modale-reseller {
        font-size: 8pt;
        bottom: 5px;
        padding: 0 5px;
    }
}

/* Stili per schermi con larghezza superiore a 900px */
@media (min-width: 901px) {
    .title-header-modale-reseller {
        display: flex;
        justify-content: center;
        /* Centra i pulsanti orizzontalmente */
        gap: 10px;
        /* Aggiunge spazio tra i pulsanti */
        height: 55px;
    }
}

@media screen and (min-width: 320px) {}

@media screen and (min-width: 320px) {
    .containeruser-list-div {
        font-size: 8pt;
    }
}

@media screen and (min-width: 360px) {

    .dropdown-container-no-caret {
        margin-right: 9.1em;
    }
}

@media screen and (min-width: 320px) {
    .dropdown-container-no-caret {
        margin-right: 9.1em;
    }
}

/*TUTTI I TELEFONI MESSI IN ORIZZONTALE*/

@media screen and (min-width: 480px) {
    .containeruser-list-div {
        font-size: 12pt;
    }
}

@media screen and (min-width: 480px) {
    .dropdown-container-no-caret {
        margin-right: 0;
    }
}

/*TABLET (DAL-AL)768.....IPAD, ma in orrizzontale diventa blu*/

@media screen and (min-width: 768px) {
    .dropdown-container-no-caret {
        margin-right: 0;
    }
}

/*Laptop (DAL-AL)1024......IPAD PRO, ma in orizzontale diventa grigio*/

@media screen and (min-width: 1024px) {
    .dropdown-container-no-caret {
        margin-right: 0;
    }
}

/*Laptop (DAL)1400-2560(AL)*/

@media screen and (min-width: 1200px) {
    .dropdown-container-no-caret {
        margin-right: 0;
    }
}