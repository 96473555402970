div.modal-footer {
    text-align: center;
}

.btn-modal-utente,
.btn-modal-utente.btn-default,
.btn-modal-utente.btn {
    margin: 2vh 2vw;
    padding: 1vh 2vw;
    background-color: #174c88;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8em;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    background-image: none;
    outline: 0;
    cursor: pointer;
    transition: all 0.15s;
    font: caption;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    font-size: small;
}

.btn-home,
.btn-home.btn-default,
.btn-home.btn {
    background-color: #5888bf;
}

.btn-modal-utente:hover,
.btn-modal-utente.btn-default:hover,
.btn-modal-utente.btn:hover,
.btn-modal-utente:active,
.btn-modal-utente.btn-default:active,
.btn-modal-utente.btn:active,
.btn-modal-utente:focus,
.btn-modal-utente.btn-default:focus,
.btn-modal-utente.btn:focus {
    color: white;
    background-color: #02afff;
}

.list-group-item.menu-item.btn-modal-utente {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 10px;
    font-weight: "bold";
    color: white;
    background-color: #174c88;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
}

.list-group-item.menu-item.btn-modal-utente:active,
.list-group-item.menu-item.btn-modal-utente:focus,
.list-group-item.menu-item.btn-modal-utente:hover {
    background-color: #02AFFF !important;
    border-color: #02AFFF !important;
}

.modal-header.input-filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    font-size: x-small;
    padding: 1.3em;
    margin: 0;
    border-bottom: 8px solid white;
}

.Select,
.Select-control,
.Select-input,
.Select-placeholder,
.Select--single>.Select-control {
    /*height: 100% !important;
    width: 100% !important;*/
    font-size: medium !important;
}

div.Select-placeholder div,
div.Select-input,
div.Select-input input {
    color: silver;
    height: 100%;
    width: 100%;
}

div.col-xs-12.field-container-reseller div div.Select.is-clearable.is-searchable.Select--single {
    height: 100%;
    width: 100%;
}

#react-select-2--value>div.Select-input>input {
    height: 100%;
    width: 100%;
}



.modal-body.input-filter {
    padding: 1.3em;
    max-width: 80%;
    margin: 0 auto;
}

.Select-control {
    height: 100%;
    min-height: 35px;
}

.list-group-item:hover {
    border-radius: 10px;
}

.field-container-reseller {
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 1em;
}

.span-title-modal-utente {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

/* LAPTOP M */
@media screen and (min-width: 992px) and (max-width: 1024px) {
    .Select-control {}

    .container-modal-reseller-user {
        display: flex;
        justify-content: center;
    }
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 991px) {
    .Select-control {}
}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    .field-container-reseller {
        display: flex;
        justify-content: center;
    }

    .modal-active.col-md-12 .modal-dialog.modal-lg .modal-content {
        margin-top: 25px;
    }

    .Select-control {
        height: 85px;
        width: 230px;
    }

    .span-title-modal-utente {
        font-size: 1.5em;
    }
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {
    .list-group-item.menu-item.btn-modal-utente {
        font-size: 1em;
    }

    .Select,
    .Select-control,
    .Select-input,
    .Select-placeholder,
    .Select--single>.Select-control {
        height: 90% !important;
        min-height: 35px;
        font-size: small !important;
    }

    .field-container-reseller {
        display: flex;
        justify-content: center;
    }

    .modal-active.col-md-12 .modal-dialog.modal-lg .modal-content {
        margin-top: 25px;
    }

    .Select-control {
        height: 85px;
        width: 200px;
    }

    .span-title-modal-utente {
        font-size: 1.5em;
    }
}