
.card-content{
    margin-top: 55px;
}

.card{
    transition: margin-top 750ms;
    will-change: margin-top;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card:hover{
    margin-top: 10px;
}

.card-body{
}

.card-heading-container{
    background-color: #174c88;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-heading-container .sectionIcons{
    color: white;
    margin-left: 10px;
}

.card-title{
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin: auto;
}

.card-text-container{
    margin-top: 15px;
}

.card-text{
    color: black;
    font-weight:600;
    text-align: center;
}

.card-text span{
    color: #333333;
}

.btn-container{
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
}

.btn-container button{
    width: 20%;
    height: 35%;
}

/* patch */

.my-auto{
    margin-top: auto!important;
    margin-bottom: auto!important;
}

.mx-auto{
    margin-left: auto!important;
    margin-right: auto!important;
}

/* left's margins */

.ml-1{
    margin-left: 0.25rem!important;
}

.ml-2{
    margin-left: 0.5rem!important;
}

.ml-3{
    margin-left: 1rem!important;
}

.ml-4{
    margin-left: 1.5rem!important;
}

.ml-5{
    margin-left: 3rem!important;
}

.ml-6{
    margin-left: 4.5rem!important;
}

.ml-7{
    margin-left: 6rem!important;
}

.ml-8{
    margin-left: 7.5rem!important;
}

.ml-9{
    margin-left: 9rem!important;
}

.ml-10{
    margin-left: 10.5rem!important;
}

/* right's margins */

.mr-1{
    margin-right: 0.25rem!important;
}

.mr-2{
    margin-right: 0.5rem!important;
}

.mr-3{
    margin-right: 1rem!important;
}

.mr-4{
    margin-right: 1.5rem!important;
}

.mr-5{
    margin-right: 3rem!important;
}

.mr-6{
    margin-right: 4.5rem!important;
}

.mr-7{
    margin-right: 6rem!important;
}

.mr-8{
    margin-right: 7.5rem!important;
}

.mr-9{
    margin-right: 9rem!important;
}

.mr-10{
    margin-right: 10.5rem!important;
}


/* top's margins */

.mt-1{
    margin-top: 0.25rem!important;
}

.mt-2{
    margin-top: 0.5rem!important;
}

.mt-3{
    margin-top: 1rem!important;
}

.mt-4{
    margin-top: 1.5rem!important;
}

.mt-5{
    margin-top: 3rem!important;
}

.mt-6{
    margin-top: 4.5rem!important;
}

.mt-7{
    margin-top: 6rem!important;
}

.mt-8{
    margin-top: 7.5rem!important;
}

.mt-9{
    margin-top: 9rem!important;
}

.mt-10{
    margin-top: 10.5rem!important;
}



/* bottom's margins */

.mb-1{
    margin-bottom: 0.25rem!important;
}

.mb-2{
    margin-bottom: 0.5rem!important;
}

.mb-3{
    margin-bottom: 1rem!important;
}

.mb-4{
    margin-bottom: 1.5rem!important;
}

.mb-5{
    margin-bottom: 3rem!important;
}

.mb-6{
    margin-bottom: 4.5rem!important;
}

.mb-7{
    margin-bottom: 6rem!important;
}

.mb-8{
    margin-bottom: 7.5rem!important;
}

.mb-9{
    margin-bottom: 9rem!important;
}

.mb-10{
    margin-bottom: 10.5rem!important;
}

.mb-11{
    margin-bottom: 12rem!important;
}

.mb-12{
    margin-bottom: 13.5rem!important;
}

.mb-13{
    margin-bottom: 15rem!important;
}

.mb-14{
    margin-bottom: 16.5rem!important;
}

.mb-15{
    margin-bottom: 18rem!important;
}

/* padding */

.p-1{
    padding: 0.25rem!important;
}

.p-2{
    padding: 0.5rem!important;
}

.p-3{
    padding: 1rem!important;
}

.p-4{
    padding: 1.5rem!important;
}

.p-5{
    padding: 3rem!important;
}

.p-6{
    padding: 4.5rem!important;
}

.p-7{
    padding: 6rem!important;
}

.p-8{
    padding: 7.5rem!important;
}

.p-9{
    padding: 9rem!important;
}

.p-10{
    padding: 10.5rem!important;
}

/* LAPTOP L  */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .btn-container{
        margin-top: -40px;
    }

    .alert-report-modal{
        margin-top: -20px !important;
        margin-bottom: 10px;
    }
}

/* LAPTOP M  */
@media screen and (min-width: 992px) and (max-width: 1024px) {
    .btn-container{
        margin-top: -40px;
    }

    .alert-report-modal{
        margin-top: -20px !important;
        margin-bottom: 10px;
    }

    .btn-container button{
        width: 30%;
        height: 35%;
    }

    .cards-modal-container{
        width: fit-content;
    }

    .icons-report-modal{
        font-size: 25px;
    }

    .card-title{
        font-size: 18px;
    }

    .card-text{
        font-size: 16px;
    }

    .card-text span{
        font-size: 15px;
    }
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 991px) {
    .btn-container{
        margin-top: -40px;
    }

    .alert-report-modal{
        margin-bottom: 45px;
    }

    .btn-container button{
        width: 50%;
        height: 55px;
    }

    .cards-modal-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .icons-report-modal{
        font-size: 20px;
    }

    .card-text-container{
        padding: 5px;
    }

    .card-title{
        font-size: 12px;
    }

    .card-text{
        font-size: 12px;
    }

    .card-text span{
        font-size: 10px;
    }

}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {

    .cards-modal-container {
        width: 100%; /* Mantém a largura total */
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        justify-content: flex-start; /* Alinha os cards à esquerda */
    }

    .card-content:first-child {
        margin-left: 20px; /* Adiciona uma pequena margem à esquerda para o primeiro card */
    }


    .btn-container{
        margin-top: -40px;
    }


    .alert-report-modal{
        width: 70%;
        margin-top: -5px !important;
        margin-bottom: 30px;
        padding: 3px !important;
    }

    .btn-container button{
        width: 100%;
        height: 35px;
        margin-bottom: 10px;
    }

    .icons-report-modal{
        font-size: 15px;
    }

    .card-title{
        font-size: 15px;
    }

    .card-text-container{
        padding: 5px;
    }

    .card-text{
        font-size: 15px;
    }

    .card-text span{
        font-size: 12px;
    }

}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {

    .cards-modal-container {
        width: 100%; /* Mantém a largura total */
        overflow-x: scroll;
        display: flex;
        flex-direction: row;
        justify-content: flex-start; /* Alinha os cards à esquerda */
    }

    .card-content:first-child {
        margin-left: 20px; /* Adiciona uma pequena margem à esquerda para o primeiro card */
    }


    .btn-container{
        margin-top: -40px;
    }


    .alert-report-modal{
        width: 70%;
        margin-top: -5px !important;
        margin-bottom: 30px;
        padding: 3px !important;
    }

    .btn-container button{
        width: 90%;
        height: 35px;
        margin-bottom: 10px;
    }

    .icons-report-modal{
        font-size: 15px;
    }

    .card-title{
        font-size: 15px;
    }

    .card-text-container{
        padding: 5px;
    }

    .card-text{
        font-size: 15px;
    }

    .card-text span{
        font-size: 12px;
    }
}