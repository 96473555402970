.container-notresalable{
    margin-top: 20px;
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 991px) {
    .container-notresalable{
        margin-top: 15px;
        margin-bottom: 15px;
    }
}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    .container-notresalable{
        margin-top: 15px;
        margin-bottom: 15px;
    }
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {
    .container-notresalable{
        margin-top: 15px;
        margin-bottom: 15px;    
    }
}