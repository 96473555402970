.info-box{
    margin-top: 3em;
    margin-bottom: 2em;
}

.row-flex, .row-flex > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex:1 1 auto;
}

.row-flex-wrap {
    -webkit-flex-flow: row wrap;
    align-content: flex-start;
    flex:0;
}

.row-flex > div[class*='col-'], .container-flex > div[class*='col-'] {
    margin:-.2px; /* hack adjust for wrapping */
}

.container-flex > div[class*='col-'] div,.row-flex > div[class*='col-'] div {
    width:100%;
}

.flex-col {
    display: flex;
    display: -webkit-flex;
    flex: 1 100%;
    flex-flow: column nowrap;
}

.flex-grow {
    -webkit-flex: 2;
    flex: 2;
}

/** The Magic **/
btn-breadcrumb>.btn.disabled {
    opacity: 1 !important;
    color: #999;
}

.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 100%;
    z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-leadeft: 10px solid rgb(173, 173, 173);
    position: absolute;
    top: 50%;
    margin-top: -17px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
    padding:6px 12px 6px 24px;
}

.btn-breadcrumb .btn:first-child {
    padding:6px 6px 6px 10px;
}

.btn-breadcrumb .btn:last-child {
    padding:6px 18px 6px 24px;
}

/** Danger button **/
.btn-breadcrumb .btn.btn-danger:not(:last-child):after {
    border-left: 10px solid #d9534f;
}

.btn-breadcrumb .btn.btn-danger:not(:last-child):before {
    border-left: 10px solid #d43f3a;
}

.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):after {
    border-left: 10px solid #d2322d;
}

.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):before {
    border-left: 10px solid #ac2925;
}
