.main-container.container {
    padding: 20px 0;
}

.splash-registration-div {
    background-color: white;
    text-align: center;
    padding-top: 4.2em;
    border-top: 0;
}
.splash-registration {
    margin-bottom: 2em;
    padding-bottom: 1em;
}

.splash-registration-logo {
    width: 66.7%;
}


/* TABLET */
@media screen and (min-width: 426px) and (max-width: 767px) {

}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    .main-container {
        margin: 10px;
    }

}


/* MOBILE M & S */
@media screen and (min-width: 320px) and (max-width: 376px) {


}
