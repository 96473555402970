.text-recovery-password h2 {
    margin: 2vh 0vw;
    padding: 1vh 0vw 2vh;
}

.text-recovery-password {
    margin: 1vh 0vw;
    padding: 1vh 0vw 1vh;
}

.inputLogin {
    margin: 1vh 0vw;
    padding: .5vh .5vw .55vh;
}

.error {
    margin-top: 3vh;
    padding: 2vh 0vw 2vh;
    color: red;
    background-color: rgba(254, 18, 18, 0.258);
    border-radius: 15px;
}

.error div, .error div span {
    padding: 2vh 0vw 3vh;
    color: red;
}

.error p {
    margin: 1vh 0vw;
    padding: 1vh 0vw 1vh;
}

.button {
    margin-top: 2vh;
    margin-bottom: 3vh;
    padding: 1vh 0vw 1vh;
}

.button .btn.mainBtn, .btn-recovery-password.btn-default, .btn-recovery-password.btn {
    margin: 1vh 1vw;
    padding: 1vh 1vw;
    background-color: #02afff;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8em;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    background-image: none;
    outline: 0;
    cursor: pointer;
    transition: all 0.15s;
    font: caption;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    font-size: small;
}

.btn-recovery-password {
    color: #f9d798 !important;
}

.button .btn.mainBtn:hover, .button .btn.mainBtn:active, .button .btn.mainBtn:focus,
.btn-recovery-password:hover, .btn-recovery-password:active, .btn-recovery-password:focus {
    color: white;
    box-shadow: inset 2px 3px 5px rgba(0,0,0,.500);
    background-color: #10b5de;
    border-radius: 10px;
}