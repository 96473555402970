.margin-btn-p{
    margin-top: 1.5em;
}

.title-danger{
    margin-top: 4rem;
    color: red;
    margin-left: 2em;
}
.title-marks{
    margin-bottom: 1em;
    color: #eb242e;
}

.text-regular-user{
    font-size: 10pt;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    color: #5a5a5a;
}
.text-bold-user {
    font-size: 15pt;
    font-family: Arial;
    font-weight: bold;
    color: #5a5a5a;
    margin-bottom: 9px;
    margin-top: -4px;
}

.title-user-categories{
    color: #174c88;
    padding: 10px;
    border-top: 1px solid #f2f1ef;
    text-align: center;
    margin-top: 1em;
}
.user-btn-personalization1{
    text-align: center;
    font-size: x-large;

}
.user-btn-personalization2{
    text-align: center;
    color: #02afff;
}


.user-card-btn{
    background-color: #174c88;
    color: white;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;    
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;    
    border: none;    
    margin: 5px;      
}
.col-personalized-user{
    margin-top: 15px;
}
.col-personalized-user2{
    margin-left: -5px;
}
.user-card-btn1{
    border-radius: 7px;
    background-color: #174c88;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.user-card-btn1:hover {
    background-color: #02afff;
    color: white;
    transition: 0.1s;
    text-decoration: none;
}
.user-card-btn1:focus {
    background-color: #02afff;
    color: white;
    transition: 0.1s;
}

.user-admin-logo {
    width: 8em;
    margin-bottom: 1.4em;
}

.user-vehicle-card{
    text-align: left;
}

.user-vehicle-card-head{
    background-color: #f6f4f6;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    padding: 10px;
    padding-left: 15px;
    margin-bottom: -6px;
}
.user-vehicle-card-body{
    background-color: #02afff;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    padding: 13px;
    padding-left: 15px;

}
.user-vehicle-card-head > .row {
    color: #5a5a5a;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 0 0;
}
.user-vehicle-card-body > .row {
    color: #ffffff;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 0 0;
}

.user-card-head-t{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #eb242e;
    color: white;
    text-align: left;
}
.user-card-head-r{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #0e7d1f;
    color: white;
    text-align: left;
}
.user-card-head-a{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #174c88;
    color: white;
    text-align: left;
}
.user-card-head-at{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #4f6b8a;
    color: white;
    text-align: left;
}
.user-card-head-c{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #eb242e;
    color: white;
    text-align: left;
}
.user-card-head-d{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: orange;
    color: white;
    text-align: left;
}
.user-card-head-p{
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #df8500;
    color: white;
    text-align: left;
}
.user-card-title{
    color: white;
    margin: 0;
    padding: 13px;
    padding-left: 15px;
}
.user-card-body{
    background-color: #f6f4f6;
    padding: 15px;
    text-align: left;
    padding-bottom: 0;
}
.user-card-body > .row {
    color: #5a5a5a;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 0 0;
}

.user-card-footer > .row {
    color: #5a5a5a;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 0 0;
}
.user-card-h{
    margin: 0;
}
.user-card-footer{
    background-color: #f6f4f6;
    padding: 15px;
    text-align: left;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    padding-top: 0;
}


.border-div {
    width: 100%;
    border-style: solid;
    border-color: rgb(221, 218, 221);
    border-right: none;
    border-left: none;
    border-width: 1px;
    height: 4px;
    line-height: 2px;
    background-color: #f6f4f6;
}

.user-card-at{
    opacity: 0.7;
}
.user-card {
    margin-bottom: 15px;
}
.btn-download-user{
    background-color: #174c88;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-size: small;
    font-weight: bold;
}
.btn-download-user:hover{
    color: white;
    text-decoration: none;
    background-color: #cb1d26;
}
.btn-download-user:focus{
    color: white;
    text-decoration: none;
    background-color: #cb1d26;
}


.btn-download-user-try{
    background-color: #02afff;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 11px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-size: small;
    font-weight: bold;
}
.btn-download-user-try:hover{
    color: white;
    text-decoration: none;
    background-color: #cb1d26;
}
.btn-download-user-try:focus{
    color: white;
    text-decoration: none;
    background-color: #cb1d26;
}

.span-prova{
    color: #4db54d
}

.col-information-user{
    background-color: white;
    border: 1px #174c88;
    border-left-style: solid;
    margin-bottom: 1.5em;
    padding-bottom: 15px;
}

.threesom-span{
    margin-right: 15px;
}

.col-btn-display{
    display: grid;
    padding: 8px;
    padding-left: 0;
}
.user-details-card{
    text-align: left;
}

.mark-numberplate-change-button{
    padding: 1px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.mark-numberplate-change-button-2{
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.btn-file-browser-confirm {
    background-color: #9e9e9e;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-size: small;
    font-weight: bold;
}
.btn-file-browser-confirm:hover {
    color: white;
    text-decoration: none;
    background-color: #02afff;
}
.btn-file-browser-confirm:focus {
    color: white;
    text-decoration: none;
    background-color: #02afff;
}

.mdl {
    max-width: 490px;
    padding: 2.5rem;
}

/*TARGA ATTIVA*/

.change-plate-button{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 16px;
}

.change-plate-button-margin {
    margin-top: 16px;
}

resident-jumbotron {
    text-align: center;
    margin-top: 2em;
    background-color: #f5f3f4;
    margin-bottom: 0.6em;
    border-radius: 0px !important;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
}

.glyphicon-list-alt {
    font-size: 10rem;
    margin-top: 2rem;
    color: #174c88;
}

.sell-control{
    border: none;
}

.park-ticket-panel:hover {
    cursor: pointer;
}

.park-ticket-panel:hover > div {
    background-color: #e0e0e0;
}

.park-ticket-panel:hover > div > div {
    background-color: #e0e0e0;
}

.park-ticket-panel.active > div {
    background-color: #e0e0e0;
}

.park-ticket-panel.active > div > div {
    background-color: #e0e0e0;
}

.park-ticket-panel.active > div > h3 {
}

.park-ticket-panel.active > div > h4 {
}

.card-marks {
    border-top: 8px solid #174c88 !important;
}

.card-marks > .caption > h5 {
    margin-bottom: 10px;
}

.card-vehicle {
    border-top: 20px solid #174c88 !important;  
    
    border-top-left-radius: 10px ;  
    border-top-right-radius: 10px ;  

    border-bottom-left-radius: 10px ;  
    border-bottom-right-radius: 10px ;  

    background-color: #f6f4f6;

    min-height: 18rem;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-bottom: 1em;
    
}

.card-vehicle-r{    
    border-top: 20px solid #4f6b8a !important;     
}

.card-vehicle-wp{
    border-top: 20px solid #df8500 !important;     
}

.card-vehicle-rp{
    border-top: 20px solid orange !important;     
}

.card-order-history {
    border-top: 20px solid #174c88 !important;  
    
    border-top-left-radius: 10px ;  
    border-top-right-radius: 10px ;  

    border-bottom-left-radius: 10px ;  
    border-bottom-right-radius: 10px ;  

    background-color: #f6f4f6;

    min-height: 15rem;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-top: 1em;
    
}

.card-documents {
    border-top: 20px solid #174c88 !important;  
    
    border-top-left-radius: 10px ;  
    border-top-right-radius: 10px ;  

    border-bottom-left-radius: 10px ;  
    border-bottom-right-radius: 10px ;  

    background-color: #f6f4f6;

    min-height: 15rem;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
}


/* STILE Veicoli */
.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 70px;
    width: 92%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.btn-lg-back{
    margin-bottom: 2em;
}

.title-special{
    margin-left: -1.9rem !important;
}
