#cookie-statement{
    display: block;
    background-color: #5a5a5a;
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    padding: 10px;
    width: 100%;
    z-index: 999;
    position: sticky;
    top: 0;
}

.cookie-banner-btn {
    background-color: #303030;
    border: 1px solid rgba(0, 0, 0, .1);
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    line-height: 19px;
    padding: 4px 8px;
    text-decoration: none;
    white-space: nowrap;
    margin: 5px;
}
.cookie-banner-btn:active {
    background-color: #303030;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}
.cookie-banner-btn:focus {
    background-color: #303030;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}
.cookie-banner-btn:hover {
    background-color: #303030;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}