.list-group-item {
    background-color: #f7f6f7;
    border-color: #f7f6f7;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 5px;
    transition: all 0.2s linear;
}

.list-group-item:hover {
    background-color: #174c88 !important;
    border-color: #174c88;
    color: white !important;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    background-color: #174c88;
    border-color: #174c88;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


div.panel>div.panel-body>div.row>div.pull-left>a.mainBtn, .selectedMainBtn.active {
    margin: 0 5px;
    margin-right: 0;
    padding: 6px 10px;
}