.cookie-btn{
    background-color: #f7f6f7;
    color: #5b5b5b;
    border: none;
    width: 100%;
    font: caption;
    padding: 10px;
    border-radius: 0;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    text-decoration: none;
    box-shadow: 0 2px 0px -1px gainsboro
}
.cookie-btn:focus{
    color: #5a5a5a;
    text-decoration: none;
}
.cookie-btn:hover{
    color: #5a5a5a;
    text-decoration: none;
}
.cookie-btn:active{
    color: #5a5a5a;
    text-decoration: none;
}