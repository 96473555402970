.messagesContent {
    display: "flex";
    flex-direction: column;
    margin-top: .1vh;
    padding: .1vh;
    background: "#f5f5f5";
    height: 100%;
}

.messageRow {
    background: #eFeFeF;
    color: #848484;
    font-size: medium;
    text-align: left;
}

.messageSelectedRow {
    background: #dFdFdF;
    color: #444444;
    font-size: medium;
    text-align: left;
}

.messageHeader {
    background: #174c88;
    color: white;
    font-size: medium;
    text-align: left;
}

.messageUnreadRow {
    background: #95aac2;    
    color: white;
    text-align: left;
    font-size: medium;
}

.messageUnreadSelectedRow {
    background: #6990bc;
    color: white;
    text-align: left;
    font-size: medium;
}
.alternativeMessage{
    padding: 10px 0;
}
.messageBtnBar  {      
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 60px;         
    border: black;
    border-style: none;
}

.messageBtnBar > .mainBtn {
    cursor: pointer;
}







