.cart-caption > div > h4 {
    margin-bottom: 15px;
}
.cart-caption > div > h5 {
    margin-bottom: 15px;
}


.cart-caption > div > div > h5 {
    margin-bottom: 15px;
}

.cart-caption{
    padding: 10px;
}