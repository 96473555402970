.backofficeSettingsBox {
    background: #f5f5f5;
    color: #848484;        
    text-align: left;        
    border-style: solid;    
    border-width: 1px;    
    border-color:  #eFeFeF;
    margin: 0;
    margin-bottom: 0em;    
    padding: 0em;
    border-radius: 0.5em;
    min-height: 20em;    
}

.backofficeSettingsBoxHeader {
    background: #174c88;
    color: white;        
    text-align: center;    
    margin: 0px;          
    border-width: 0px;                
    margin-bottom: 1em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;    
    min-height: 2.5em;
    padding: 0.5em;
}

.backofficeSettingsHeader {
    background: #174c88;
    color: white;       
    text-align: left;    
    max-height: 2.5em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    
    border-color:  #174c88;  
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;    
}

.backofficeSettingsHeader div {
    margin: 0;
    padding: 0.3em;
    padding-left: 0.5em;    
}

.backofficeSettingsRow {
    background: #eFeFeF;
    color: #848484;    
    text-align: left;
    min-height: 3em;    

    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;

    border-color:  grey;
}

.backofficeSettingsRow:hover {
    background: #dFdFdF;        
}

.backofficeSettingsRow div {
    margin: 0;
    padding: 0.3em;            
}

.backofficeSettingsRowSelected {
    background: #dFdFdF;  
    color: #848484;    
    text-align: left;
    min-height: 3em;
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeSettingsRowSelected:hover {
    background: #cFcFcF;        
}

.backofficeSettingsRowSelected div {
    margin: 0;
    padding: 0.3em;            
}

.backofficeSettingsFooter {
    background: #174c88;
    color: white;   
    text-align: left;
    max-height: 0.5em;    
    margin-left: 0.1em;
    margin-right: 0.1em;
    margin-top: 0em;
    margin-bottom: 0em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  #174c88;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    
}

.backofficeSettingsFooter div {
    margin: 0.5em;
}






