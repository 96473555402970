.backofficeMessageRow {
    background: #eFeFeF;
    color: #848484;    
    font-size: 14px;
    text-align: left;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeMessageRow td {
    padding: 0.5em;
}

.backofficeMessageSelectedRow {
    background: #dFdFdF;
    color: #444444;    
    font-size: 14px;
    text-align: left;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeMessageSelectedRow td {
    padding: 0.5em;
}

.backofficeMessageHeader {
    background: #174c88;
    color: white;   
    font-size: 14px; 
    text-align: left;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  #174c88;

    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.backofficeMessageHeader td {
    padding: 0.5em;
}

.backofficeMessageFooter {
    background: #174c88;
    color: white;   
    font-size: 14px; 
    text-align: left;
    margin-top: 0;
    max-height: 0.7em;
    min-height: 0.7em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  #174c88;

    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}

.backofficeMessageFooter td {
    padding: 0.2em;
}

.backofficeMessageUnreadRow {
    background: #95aac2;    
    color: white;
    text-align: left;    
    font-size: 14px;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeMessageUnreadRow td {
    padding: 0.5em;
}

.backofficeMessageUnreadSelectedRow {
    background: #6990bc;
    color: white;
    text-align: left;    
    font-size: 14px;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeMessageUnreadSelectedRow td {
    padding: 0.5em;
}

.backofficeMessageBtnBar  {      
    margin: 0;
    padding: 0;
    height: 60px; 
    background:  transparent;
    color: white;
    border: black;
    border-style: none;
}