a {
    cursor: pointer;
}

.truncate {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-info {
    background-color: #174c88;
}

.Select-control {
    padding: 10px;
}

.Select-placeholder {
    padding: 0 20px;
}

.panel-body {
    text-align: center;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.panel-body-dark {
    text-align: center;
    background-color: #f7f6f7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0.5em;
}

.panel-border {
    border-color: #174c88;
    border-style: solid none none;
    border-width: 10px 0 0 0;
    margin-bottom: 20px;    
    border-radius: 10px;
}

.panel {    
    border-radius: 10px;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;   
}

.panel-menu {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    box-shadow: none;    
    background-color: transparent;
}

.row-list-body {
    border-color: grey;
    border-width: 1px;
    border-style: dashed;
    border-radius: 10px;
    margin: 0px;
    margin-top: 10px;
    padding: 0px; 
    min-height: 0;   
}

.cell-list-body {
    padding: 5px;
    margin: 0px;
    min-height: 0;
    color: rgb(73, 73, 73);
}

.form-error {
    border: 1px solid #eb242e;
    box-shadow: 4px 4px 10px rgba(200, 0, 0, 0.85);
}

input:focus:invalid, select:focus:invalid {
    box-shadow: 4px 4px 10px rgba(200, 0, 0, 0.85);
    border: 1px solid #eb242e;
}

.react-grid-Empty {
    padding:1em;
}

.react-datepicker {
    font-size: 1.3rem;
}

.react-datepicker__current-month {
    font-size: 1.5rem;
}

.react-datepicker__header {
    padding-top: 6px;
}

.react-datepicker__navigation {
    top: 13px;
}

.react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem;
}

.titolo-categoria legend {
    margin-top:1.5em;
    color: #174c88;
}

.titolo-categoria-2 {
    margin-top:1.5em;
    color: #848484;
}

label.sex {
    padding-left: 1em;
    padding-bottom: 1em;
}

::-webkit-input-placeholder { /* WebKit browsers */
    color: #AAAAAA;
}
::-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #AAAAAA;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #AAAAAA;
}
::-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #AAAAAA;
}

.no-horizontal-border {
    border-right: 0 !important;
    border-left: 0 !important;
}

.documents-browser-header-list {
    padding-top: 3px;
    padding-left: 3px;
    padding-bottom: 3px;
    font-weight: bold;
    text-align: left;
}

.documents-browser-file-cell {   
    height: 50px; 
    padding-top: 10px;
    padding-bottom: 3px;
    padding-left: 3px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

.documents-browser-file-cell-nopad {   
    height: 50px;  
    padding-left: 3px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

.white-text {
    color: #ffffff;
}

.react-datepicker__input-container {
    width: 100%;
}

div > .react-datepicker__input-container {
    width: 100%;
}

.react-time-picker__wrapper {
    height: 34px;
    padding: 6px 12px;
    border: 1px solid #ccc;
}


.textError {
    color: #ee0000;
    font: bold;
}

.centerPositionBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* UTILS */
.pb10{
    padding-bottom: 10px;
}


/* ============================================================
 *  CUSTOM SCROLLBAR
 */
::-webkit-scrollbar {
    width: 7px;
    }

  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 20px;
    }
    
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 20px;
    }
    
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
    }


/* ============================================================
 *  SECTIONS CSS 
 */
.sectionTitle {
    color: #174c88;
    text-align: left;
    border-bottom: 1px none #dddddd;
    padding: 8px;
    margin-bottom: 0.3em;
}

.sectionIcons {
    color: #174c88; 
}

/* ============================================================
 *  INPUT FIELDS CSS 
 */
.mainSelect {
    height: 10px;
}

.mainField {
    width: 100%;
    margin-bottom: 1.4rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.2rem;
    border: solid;
    border-width: 1px;
    border-color: #cccccc;
    height: 30px;
}

.mainInput {
    width: 100%;
    margin-bottom: 1.4rem;
    padding: 1rem;
    background-color: #f6f6f6;
    border-radius: 0.2rem;
    border: none;
    padding: 0;
}

.secondaryInput {        
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.5em;    
    margin: 0.5em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    background-color: #f6f6f6;
    border: solid 1px gray;    
}

/* ============================================================
 *  DROPDOWN CSS 
 */
.react-dropdown-tree-select  {
    min-width: 100%;
    margin: 0;   
    padding: 0;
    background-color: white;    
    border: none;       
}

.react-dropdown-tree-select>.dropdown  {
    min-width: 100%;
    margin : 0;
    padding: 0;
    border: 1px solid #cccccc;    
    border-radius: 0.2em;
}

.react-dropdown-tree-select>.dropdown>.dropdown-trigger {
    margin: 0;
    padding: 0;
    min-width: 100%;
    background-color: transparent;    
    border: none; 
    height: 100%;    
}

.react-dropdown-tree-select>.dropdown>.dropdown-trigger>.tag-list {        
    margin : 0;
    padding: 0;
    border: none;
}

.react-dropdown-tree-select>.dropdown>.dropdown-trigger>.tag-list>.tag-item {
    margin: 0.2em;
    padding: 0em;
    border: none;
}

.react-dropdown-tree-select>.dropdown>.dropdown-trigger>.tag-list>.tag-item>.tag>.tag-remove {
    margin: 0em;
    font-size: large;
    font-weight: bold;
    color: darkred;
}

.react-dropdown-tree-select>.dropdown>.dropdown-trigger>.tag-list>.tag-item>.search {
    text-decoration: none;
    border: none;    
}

.react-dropdown-tree-select>.dropdown>.dropdown-content {
    margin: 0;
    margin-top: 0.2em;
    padding: 0.5em;
    min-width: 100%;
    background-color: white;    
    border: none;     
}

.react-dropdown-tree-select>.dropdown>.dropdown-content>.root>div>.infinite-scroll-component>.node  {
    padding: 0.2em;   
}

.react-dropdown-tree-select>.dropdown>.dropdown-content>.root>div>.infinite-scroll-component>.node>label>  {
    vertical-align: middle;
}

.react-dropdown-tree-select>.dropdown>.dropdown-content>.root>div>.infinite-scroll-component>.node>label>input  {
    margin-right: 0.5em;
    margin-bottom: 0.5em;    
}

.dropdown-menu {    
    border-radius: 0.5em;        
    border-width: 1px;
    border-style: solid;
    border-color: white;
}



/* ============================================================
 *  BUTTONS CSS 
 */

.tableBtn {
    background-color: #174c88;
    color: white;
    border: none;    
    font: caption;
    padding: 0.3em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;    
    margin-right: 5px;    
}

.tableBtn.active {
background-color: #02afff;
color: white;
}

.tableBtn:hover , .tableBtn.active:hover , .tableBtn:focus {
background-color: #02afff;
color: white;
text-decoration: none;
}


.mainBtn {        
    background-color: #174c88;
    color: white;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.5em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;    
    /*margin: 0.5em;*/
    margin: 0.5em 0.5em 0.5em 0.4em;
    cursor: pointer;
    transition: all 0.15s linear;
}

.mainBtn:hover {
    background-color: #02afff;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8em;
}

.mainBtn:active:focus {
    background-color:#174c88;
    color: white;
    text-decoration: none;
    text-align: center;
}

.mainBtnLarge {
    background-color: #174c88;
    color: white;
    border: none;
    width: 100%;
    font: caption;
    padding: 1em;
    border-radius: 0.5em;
}
.mainBtnLarge:hover {
    background-color: #02afff;
    color: white;
}

.mainBtnLarge:active:focus {
    background-color: #174c88;
    color: white;
}

.selectedMainBtn {        
    background-color: #02afff;
    color: white;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;    
    margin: 0.5em;    
}

.selectedMainBtn:hover {
    background-color: #02afff;
    color: white;
    text-decoration: none;
    text-align: center;
}

.alertBtn {    
    background-color: #cb1b24;
    color: white;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;
    margin: 0.5em;
}

.alertBtn:hover {
    background-color: #f72933;
    color: white;
    text-decoration: none;
    text-align: center;
}

.alertBtn:active:focus {
    background-color: #eb242e;
    color: white;
    text-decoration: none;
    text-align: center;
}

.successBtn {    
    background-color: #128f4c;
    color: white;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;
    margin: 0.5em;
}

.successBtn:hover {
    background-color: #1dab5f;
    color: white;
    text-decoration: none;
    text-align: center;
}

.successBtn:active:focus {
    background-color: #128f4c;
    color: white;
    text-decoration: none;
    text-align: center;
}

.cancelBtn {    
    background-color: #dddddd;
    color: #2e2d31;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;
    margin: 0.5em;
}

.cancelBtn:hover {
    background-color: #959393;
    color: white;
    text-decoration: none;
    text-align: center;
}

.cancelBtn:active:focus {
    background-color: #dddddd;
    color: #2e2d31;
    text-decoration: none;
    text-align: center;
}

.neutralBtn {           
    background-color: #dddddd;
    color: #2e2d31;
    border: none;    
    font: caption;
    padding: 7px;
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0.4em;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    border: none;
    margin: 0.5em;
}

.neutralBtn:hover {
    background-color: #959393;
    color: white;
    text-decoration: none;
    text-align: center;
}

.neutralBtn:active:focus {
    background-color: #dddddd;
    color: #2e2d31;
    text-decoration: none;
    text-align: center;
}

.btnOnCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* modifica la classe di bootstrap  */
.form-control {
    height: 30px;
}

/* ============================================================
 *  SELECT CSS 
 */
.Select , .Select-control, .Select-input, .Select-placeholder, .Select--single > .Select-control  {
    height: 28px;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    font-weight: normal;
    font-size: small;    
    text-decoration: none;    
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, 
.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #848484;
}


/* ============================================================
 *  LABELS CSS 
 */

.boxLabel{
    font: caption;
    margin: 0 5px;
    padding: 10px 20px;
    border-radius: 7px;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    overflow-y: auto;
    max-height: 250px;
    height: 100%;
    overflow-y: auto;
    color: black;
    background-color: rgb(239, 233, 233);
}

.successLabel {    
    border: 6px solid #0b5d32;
}

.alertLabel {    
    border: 6px solid #cb1b24;
}

.warningLabel {    
    border: 6px solid orange;
}

.neutralLabel {           
    border: 6px solid #6f6f6f;
}

.carousel.carousel-slider .control-arrow {
    height: 40px;
    width: 40px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.carousel.carousel-slider .control-arrow.control-prev{
    left: 10px;
}
.carousel.carousel-slider .control-arrow.control-next{
    right: 10px;
}
.carousel .control-prev.control-arrow:before{
    border-right: 8px solid grey;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid grey;
}
/* ============================================================
 *  MODAL CSS 
 */
.app-modal-container-small {
    border-radius: 10px;
    padding: 0;
    min-width: 50%;
    margin: 0;
} 

.app-modal-container {
    border-radius: 10px;
    padding: 0;
    min-width: 70%;
    margin: 0;
} 

.app-modal-container-large {
    border-radius: 10px;
    padding: 0;
    min-width: 90%;
    margin: 0;
} 

.app-modal-title {
    background: #174c88;
    color: white;
    display: flex;  
    justify-content : 'flex-start';
    height: 45px;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 5px;
    margin: 0px;
    margin-bottom: 20px;
}

.app-box-title {        
    display: flex;  
    justify-content : 'flex-start';
    height: 45px;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 5px;
    margin: 0px;
    margin-bottom: 20px;
}

.app-modal-body {    
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;    
}

/* ============================================================
 *  BADGES CSS 
 */
.badgeInactive {
    background-color: rgb(109, 109, 109);
    color: white;
}

.badgeActive {
    background-color: #02afff;
    color: white;
}

.badgeMain {
    color: white;
}

.badgeOk {
    color: #128f4c;
}

.badgeWarning {
    color: orange;
}

.badgeAlarm {
    color: red;
}


/* ============================================================
 *  NOTE CSS 
 */
.noteRow {
    border-bottom: 1px solid gray;
    min-height: 2.5em;
    padding-bottom: 1em;
    margin: 1em;
}

.noteRowNoMargin {
    border-bottom: 1px solid gray;
    min-height: 2.5em;    
    margin: 0em;
    padding: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;    
}


/* ============================================================
 *  ICONS CSS 
 */
.mainIcons {
    padding-left: 4px;
    padding-right: 4px;
    color: #174c88;
}

.mainIcons:hover {
    color: #1783fe;
}

.mainIconsRev {
    padding-left: 4px;
    padding-right: 4px;
    color: white;
}

.mainIconsRev:hover {    
    color: #174c88;
    background: white;       
}

.mainIconsNoAction {
    padding-left: 4px;
    padding-right: 4px;
    color: #174c88;
}

.mainIconsRevNoAction {
    padding-left: 4px;
    padding-right: 4px;
    color: white;
}

/* PAGINATION */
.customBtnPage {
    border-radius: 50%;
    padding: 5px 11.5px;
    border: 2px solid #174c88;
    background-color: lightgray;
}

.ableBtnPage {
    background-color: lightgray;
    transition: all 0.15s linear;
}
.ableBtnPage:hover {
    border-color: #02afff !important;
    background-color: white;
}
.ableBtnPage:hover .sectionIcons {
    color: #02afff !important;
}

.releaseDisableMark{
    margin-bottom: 20px;
    border-bottom: 1px solid black;
}

.disableBtnPage {
    background-color: lightgray;
    border-color: lightgray;
}

.disableBtnPage svg {
    color: rgb(190, 186, 186) !important;
}

/* Formattazione pagina pdf richiesta permesso disabili */
.paragrafo{
    background-color: rgb(232, 227, 227);
    border-radius: 20px;
    padding: 30px 20px;
    margin: 30px 0;
}