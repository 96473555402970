.container-reseller-home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
}

.start-button, .start-button.btn-default, .start-button.btn {
    margin: 0vh 5vw;
    padding: 1vh 1vw;
    background-color: #174c88;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8em;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    background-image: none;
    outline: 0;
    cursor: pointer;
    transition: all 0.15s;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    font-size: large;
}

.start-button:hover, .start-button.btn-default:hover, .start-button.btn:hover {
    background-color: #02afff;
}


.panel-reseller-home {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

#root > div > section > div > div:nth-child(2) > div > div > div.col-sm-10.sidebar-right > div > div > div > div > div.col-md-4 > svg.svg-inline--fa.fa-user.fa-7x.sectionIcons > path
.sectionIcons {
    /*padding: 2vh 2vw;*/ /* eliminato perché decentra l'icona messaggi nella modale */
    padding: 0 2vw;
}

.centratore {
    padding-top: 10vh;
    padding-bottom: revert-layer;
}

a:hover,
a:active,
a:focus {
    text-decoration: none;
}

div.panel-default>h1.login-title {
    margin-top: 0vh;
    font-size: 3vw;
    margin-bottom: 2vh;
    padding-top: 1vh;
    padding-bottom: 3vh;
}

div>div>div.col-sm-10.sidebar-right {
    margin-top: 6vh;
}

@media screen and (min-width: 376px) and (max-width: 991px) {
    .centratore {
        padding-top: 2vh;
    }

    div.panel-default>h1.login-title {
        margin-top: 0vh;
        font-size: 3vw;
        margin-bottom: 2vh;
        padding-top: 1vh;
        padding-bottom: 3vh;
    }

    div>div>div.col-sm-10.sidebar-right {
        margin-top: 4vh;
    }

}

@media screen and (min-width: 426px) and (max-width: 768px) {
    .container-reseller-home-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .sectionIcons {
        max-height: 20vh;
        max-width: 20vw;
    }

    div>div>div.col-sm-10.sidebar-right {
        margin-top: 6vh;
    }
}

/* modalità mobile verticale 360px */
@media screen and (min-width: 318px) and (max-width: 376px) {
    .sectionIcons {
        max-height: 15vh;
        max-width: 15vw;
    }

    .centratore {
        padding-top: 1vh;
    }

    div>div>div.col-sm-10.sidebar-right {
        margin-top: -1vh;
    }
}

@media screen and (min-width: 1650px) {
    .sectionIcons {
        max-height: 20vh;
        max-width: 20vw;
    }

    .centratore {
        padding-top: 2vh;
    }
}