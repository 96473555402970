.backofficeMewsRow {
    background: #eFeFeF;
    color: #848484;    
    font-size: 14px;
    text-align: left;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  grey;
}

.backofficeMewsRow td {
    padding: 0.5em;
}


.backofficeNewsHeader {
    background: #174c88;
    color: white;   
    font-size: 16px; 
    text-align: left;

    min-height: 3em;    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  #174c88;

    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.backofficeNewsHeader td {
    padding: 0.5em;
}

.backofficeNewsFooter {
    background: #174c88;
    color: white;   
    font-size: 14px; 
    text-align: left;
    margin-top: 0;
    max-height: 0.7em;
    min-height: 0.7em;
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-color:  #174c88;

    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
}

.backofficeNewsFooter td {
    padding: 0.2em;
}







