.mainBtn{
    margin: 0.5em 0.5em 0.5em 0;
}


.block-no-info {
    border:0;
}

.btn-modal-utente,
.btn-modal-utente.btn-default,
.btn-modal-utente.btn {
    margin: 2vh 2vw;
    padding: 1vh 2vw;
    background-color: #174c88;
    color: white;
    text-decoration: none;
    text-align: center;
    border-radius: 0.8em;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    background-image: none;
    outline: 0;
    cursor: pointer;
    transition: all 0.15s;
    font: caption;
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    font-size: small;
}

.btn-home,
.btn-home.btn-default,
.btn-home.btn {
    background-color: #5888bf;
}


.block-info {
    margin-bottom: 40px;
    border-left: 6px solid #174c88;
    margin-left: 1em;
}

.dataUser {
    background-color: #f5f5f5;
    padding: 1em;
    margin-top: 1em;
}

.button-profile {
    margin-left: 11%;
    margin-top: 10px;
}

.nuovo-veicolo{
    margin-left:11%;
}

.my-modal1{
position: relative;
display: block;
}

.my-modal2{
    border:none;
    border-radius: inherit;
    background-color: #F1F1F1;
}

.card .caption {
    padding: 5px;
    color: #333;
    padding-left: 0px;
    padding-right: 0px;    
}

.card {
    display: block;
    padding: 4px;
    margin-bottom: 1em;
    line-height: 1.42857143;
    background-color: #f5f5f5;
    border:none;
    border-radius: 10px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    min-height: 15em;
}

.card-vehicles {
    border-top: 10px solid #174c88 !important;
    display: block;
    padding: 4px;
    margin-bottom: 1em;
    line-height: 1.42857143;
    background-color: #f5f5f5;
    border:none;
    border-radius: 10px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    min-height: 15em;
}

.card-vehicles-request{
    border-top: 10px solid orange !important;    
}

.card-vehicles-request-detail {         
    border: 0px solid lightgrey;
    border-top: 1px solid lightgrey !important;    
    margin: 0em;
    margin-top: 1em;
    margin-bottom: 0em;
    padding: 1em;    
    background: transparent;
    color: black;
    border-radius: 10px;
}

.card-vehicles-approved{
    border-top: 10px solid #df8500 !important;    
}

.card-vehicles-denied{
    border-top: 10px solid #eb242e !important;    
}

.card-vehicles-done{
    border-top: 10px solid #174c88 !important;    
}

.card-vehicles-reply{
    border-top: 10px solid orange !important;    
}

.card-marks{
    border-top: 10px solid #174c88 !important;
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #f5f5f5;
    border:none;
    border-radius: 10px;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    min-height: 308px;
}
.card-marks-e{
    border-top: 10px solid darkred !important;    
}
.card-marks-r{
    border-top: 10px solid #0e7d1f !important;    
}
.card-marks-at{
    border-top: 10px solid #6f7c8b !important;        
}
.card-marks-c{
    border-top: 10px solid #eb242e !important;        
}
.card-marks-d{
    border-top: 10px solid orange !important;        
}
.card-marks-p{
    border-top: 10px solid #df8500 !important;        
}

.btn-update {
    margin-left: 15px;
    font-weight: bold;
}

.btn-lg-back{
    margin-right: 1em;
}

.mytoolshit{
    background-color: rebeccapurple;
    display: flex;
}

.test-tooltip {
    width: 200px;
}

.icon-tooltip-div{
    float: right;
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.container-reseller-user-detail-screen{
    margin: 25px;
}

.container-reseller-user-detail-modal{
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

/* LAPTOP M VER COMO QUE NO MOBILE E LAPTOP FICA CENTRADO ENQUANTO NO LAPTOP FICA TORTO */
@media screen and (min-width: 992px) and (max-width: 1024px) {
    .static-modal .my-modal1 {
        margin-top: 25px;
    }


    .static-modal .my-modal1 .modal-dialog {
        margin-top: 25px;
    }
}

/* TABLET */
@media screen and (min-width: 426px) and (max-width: 991px) {
    .static-modal .my-modal1 {
        margin-top: 25px;
        position:relative;
        display: flex;
        justify-content: center;
    }

    .static-modal .my-modal1 .modal-dialog {
        width: 500px;
    }

    .modal-header .text-center {
        text-align: right;
    }

    .chiudi-btn {
        position: absolute; /* Position absolute in relazione al container dad */
        top: 10px;
        right: 10px;
    }

    h3.title {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h3.title .mainBtn{
        margin-top: 15px;
    }

    .btn-scarica-reseller{
        margin-top: -10px !important;
    }

}


/* MOBILE L */
@media screen and (min-width: 377px) and (max-width: 426px) {
    .static-modal .my-modal1 {
        margin-top: 25px;
    }

    .modal-header .text-center {
        text-align: right;
    }

    .chiudi-btn {
        position: absolute; /* Position absolute in relazione al container dad */
        top: 10px;
        right: 10px; 
    }

    h3.title {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h3.title .mainBtn{
        margin-top: 15px;
        margin-left: -5px;
    }

    .btn-scarica-reseller{
        margin-top: -10px !important;
    }
}


/* MOBILE M & S */
@media screen and (min-width: 318px) and (max-width: 376px) {
    .static-modal .my-modal1 {
        margin-top: 25px;
    }

    .modal-header .text-center {
        text-align: right;
    }

    .chiudi-btn {
        position: absolute; /* Position absolute in relazione al container dad */
        top: 10px;
        right: 10px; 
    }

    h3.title {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h3.title .mainBtn{
        margin-top: 15px;
    }

    .btn-scarica-reseller{
        margin-top: -10px !important;
    }
}