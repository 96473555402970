.registration-box{
    /* height: 490px; */
}
.registration-box-title{
    min-height: 67px;
}

.panel-2{
    /* min-height: 123px; */
}
