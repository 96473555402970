/* =======================================================
*
* 	Template Style
*	Edit this section
*
* ======================================================= */

body {
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 1.6;
    font-size: 14px;
    background: #f2f1ef;
    color: #848484;
    font-weight: 300;
    overflow-x: hidden;
    margin:0;
    padding:0;
}
body.fh5co-offcanvas {
    overflow: hidden;
}

a, button {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}


p, ul, ol {
    margin-bottom: 1.5em;
    font-size: 16px;
    color: #848484;
    font-family: "Open Sans", Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    color: #5a5a5a;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    margin: 0 0 30px 0;
}

figure {
    margin-bottom: 2em;
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 */

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}