.nav-user {
    background-color: #174c88;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-width: 0;        
}

.header-canvas {    
    background-color: #174c88;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    border-radius: 0;
    border-width: 0;
}

/*SOLO VERTICALE*/
/*TUTTI I TELEFONI MESSI IN VERTICALE*/

@media screen and (min-width: 320px) {
    .nav-user {
        font-size: 10px;
    }
}

@media screen and (min-width: 320px) {
    .navuser-list-div {
        font-size: 8pt;
    }
}

@media screen and (min-width: 360px) {
    .dropdown-nav-no-caret {
        margin-right: 9.1em;
    }
}
@media screen and (min-width: 320px) {
    .dropdown-nav-no-caret {
        margin-right: 9.1em;
    }
}

/*TUTTI I TELEFONI MESSI IN ORIZZONTALE*/
@media screen and (min-width: 480px) {
    .nav-user {
    }
}
@media screen and (min-width: 480px) {
    .navuser-list-div {
        font-size: 12pt;
    }
}
@media screen and (min-width: 480px) {
    .dropdown-nav-no-caret {
        margin-right: 0;
    }
}

/*TABLET (DAL-AL)768.....IPAD, ma in orrizzontale diventa blu*/

@media screen and (min-width: 768px) {
    .nav-user {
    }
}
@media screen and (min-width: 768px) {
    .dropdown-nav-no-caret {
        margin-right: 0;
    }
}
/*Laptop (DAL-AL)1024......IPAD PRO, ma in orizzontale diventa grigio*/

@media screen and (min-width: 1024px) {
    .nav-user {
    }
}
@media screen and (min-width: 1024px) {
    .dropdown-nav-no-caret {
        margin-right: 0;
    }
}
 /*Laptop (DAL)1400-2560(AL)*/
@media screen and (min-width: 1200px) {
    .nav-user {
    }
}

@media screen and (min-width: 1200px) {
    .dropdown-nav-no-caret {
        margin-right: 0;
    }
}

.li-list{
    color: white;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-size: smaller;
    margin: 0 5px !important;
    transition: all 0.2s linear;
}

.li-list:hover {
    background-color: #02afff;
    transform: scale(1.1);
}

.li-home-list{
    color: white;
    background-color: #02afff;
    font-weight: bold;
    font-size: smaller;
    text-align: center;
    margin: 0 5px !important;
    cursor: pointer;
    transition: all 0.2s linear;
}

.nav-dropdown-user-id{
    display: flex;
    background-color: #c5de9e;
    color: #006f4a;
    font-weight: bold;
    cursor: default;
    text-align: left;
    padding: 14px;
    font-size: smaller;
}

.user-id-nav {
    width: 100%;
}

.nav-user-name {
    color: #006f4a;
}

.dropdown-nav-no-caret{
    padding: 21px 20px;
    background-color: #174c88;
    color: white;
    border: none;
    border-radius: 0;
    font-weight: bold;
    font-size: smaller;
    text-align: left;
}


.open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
    background-color: #02afff;
    color: white;
    border: none;
    box-shadow: none;
}

.dropdown-nav-no-caret:hover{
    background-color: #174c88;
    color: white;
}

.dropdown-nav-no-caret:focus{
    background-color: #174c88;
    color: white;
}

.dropdown-nav-no-caret:active{
    background-color: #174c88;
    color: white;
}


.col-personalized-user-nav1{
    padding-right: 0;
    margin-right: 0;
    font-size: 12pt;    
}

.dropdown-nav-no-caret:hover{
    background-color: #02afff;
    color: white;
}

.logo-nav1{
    width: 15px;
    margin-right: 11px;
    margin-bottom: 2px;
}

.logo-nav2{
    width: 30px;
    margin-left: 8px;
    padding-top: 7px;
}

.navbar-default .navbar-nav>li>a{
    color: white !important;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover{
    background-color: #02afff;
}

.badge-menu {
    background-color: #02afff;
    transition: all 0.2s linear;
}
.li-list:hover .badge-menu, .li-home-list .badge-menu {
    background-color: white;
    color: #02afff;
}

.backoffice-dropdown{
    transition: all 0.15s linear;
}
.backoffice-dropdown:hover{
    background-color: #02afff;    
}

.backoffice-dropdown:hover .badge-menu, .backoffice-dropdown.dropdown.open .badge-menu {
    background-color: white;
    color: #02afff;
}

.badge-list{
    background-color: #d2cdc8;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    margin: 1em;
}