.navbar-reseller {
    background-color: #174c88;
    margin-bottom: 1vw;
    padding: 1vh 1vw 1vh 1vw;
    border-radius: 0;
    border-width: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
} /*OK*/

.nav-logos {
    float: left !important;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    margin-left: 0;
    width: 50vw;
} /*OK*/

.nav-buttons {
    float: right !important;
    display: flex;
    align-items: flex-end;
    justify-content: right;
    align-content: center;
    margin-right: 0;
    width: 50vw;
    height: 100%;
} /*OK*/

.logo-input,
.logoCity,
.logoThree,
.logo-item .logo-link,
.logo-item .logo-link img,
.logo-input > a,
.logoCity > a,
.logoThree > a,
.logo-item .logo-link > a,
.logo-item .logo-link > a > img {
    transition: transform 0.15s linear;
    width: auto;
    height: auto;
    max-height: 50px;
    background-color: transparent;
    text-decoration: none;
} /*OK*/

.logoThree:hover, .logoCity:hover, .logo-input:hover, .logo-item .logo-link:hover, .logo-item .logo-link img:hover, .logoThree>a:hover, .logoCity>a:hover, .logo-input>a:hover, .logo-item .logo-link>a:hover, .logo-item .logo-link>a:hover>img:hover, .logoThree>a:focus, .logoThree>a:hover, .logoThree>a:active  {
    transform: scale(1.2);
} /*OK*/


.navbar-reseller .container {
    width: 100%;
    display: flex; /* sballa la nav mettendo tutto a sinistra */
    justify-content: justify;
    align-items: center;
    align-content: right;
}

.header-canvas {
    background-color: #174c88;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    border-radius: 0;
    border-width: 0;
}

@media screen and (max-width: 768px) {
    .navbar-reseller {
        padding: 8px 2px 2px 0;
    }

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: -.5rem;
    }
}

@media screen and (min-width: 990px) {
    .Reseller-Status {
        margin: 1.1rem 1.6rem 1.1rem 0 !important;
    }
}

/*TUTTI I TELEFONI MESSI IN ORIZZONTALE*/
@media screen and (min-width: 480px) {

    .navuser-list-div {
        font-size: 12pt;
    }

    .dropdown-nav-no-caret {
        margin-right: 0;
    }
}


/* MOBILE L */
@media screen and (max-width: 426px) {

    .navbar-brand {
        padding: 4px 4px;
    }

    .logo-input {
        margin: auto 0;
        margin-top: 2px;
        max-width: 100%;
        width: 40px;
    }

    .logo-link .logo-item {
        width: auto;
        height: 60px;
        /* Ajuste conforme necessário */
        max-width: 120px;
        min-height: 30px;
        margin-bottom: 15px;
    }

    .logoThree {
        width: auto;
        height: 60px;
        /* Ajuste conforme necessário */
    }

    .Reseller-Status {
        /*margin: 0.2rem 1.6rem 0.1rem 0;*/
    }

    #Situazione-Reseller {
        /*margin: 0.2rem 1.6rem 0.1rem 0;*/
    }
}


/* MOBILE M & S */
@media screen and (min-width: 320px) and (max-width: 376px) {

    .navbar-brand {
        padding: 4px 4px;
    }

    .nav-logos {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        margin-top: -4px;
        margin-bottom: 0px;
        width: 80%;
        height: 80%;
        max-height: 60px;
    }

    .nav-logos .logo-input {
        margin: auto 0;
        margin-top: 2px;
        width: 40px;
    }

    .logo-link .logo-item {
        width: auto;
        height: 50px;
        max-width: 120px;
        min-height: 30px;
        margin-bottom: 15px;
    }

    .logo-input,
    .logoCity,
    .logoThree {
        width: auto;
        height: 80%;
        max-height: 40px;
    }
/* */
    .nav-logos>li>a {
        padding: 2vw;
    }

    .nav-buttons{
        margin-right: 10px;
    }

}

.li-list {
    color: white;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    font-size: smaller;
    margin: 0 5px !important;
    transition: all 0.2s linear;
}

.li-list:hover {
    background-color: #02afff;
    transform: scale(1.2);
}

.li-home-list {
    color: white;
    background-color: #02afff;
    font-weight: bold;
    font-size: smaller;
    text-align: center;
    margin: 0 5px !important;
    cursor: pointer;
    transition: all 0.2s linear;
}

.nav-dropdown-user-id {
    display: flex;
    background-color: #c5de9e;
    color: #006f4a;
    font-weight: bold;
    cursor: default;
    text-align: left;
    padding: 14px;
    font-size: smaller;
}

.user-id-nav {
    width: 100%;
}

.nav-reseller-name {
    color: #006f4a;
}

.dropdown-nav-no-caret {
    padding: 21px 20px;
    background-color: #174c88;
    color: white;
    border: none;
    border-radius: 0;
    font-weight: bold;
    font-size: smaller;
    text-align: left;
}


.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    background-color: #02afff;
    color: white;
    border: none;
    box-shadow: none;
}

.dropdown-nav-no-caret:hover {
    background-color: #174c88;
    color: white;
}

.dropdown-nav-no-caret:focus {
    background-color: #174c88;
    color: white;
}

.dropdown-nav-no-caret:active {
    background-color: #174c88;
    color: white;
}


.col-personalized-user-nav1 {
    padding-right: 0;
    margin-right: 0;
    font-size: 12pt;
}

.dropdown-nav-no-caret:hover {
    background-color: #02afff;
    color: white;
}

.logo-nav1 {
    width: 15px;
    margin-right: 11px;
    margin-bottom: 2px;
}

.logo-nav2 {
    width: 30px;
    margin-left: 8px;
    padding-top: 7px;
}

.navbar-default .navbar-nav>li>a {
    color: white !important;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background-color: #02afff;
}

/* ####### Pulsanti e Dropdown ####### */

/* Ma chi vorrebbe dei Pulsanti Grigi? */
.btn-default:active:hover,
.btn-default:hover,
.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    /*transform: scale(1.2);*/
    color: white;
    background-color: #0095ff;
    border-color: #0897fd;
    /*border: 2px solid #0897fd;*/
    transition: transform 0.15s linear, color 0.15s linear;
}


.badge-menu {
    background-color: #02afff;
    transition: all 0.2s linear;
}

.li-list:hover .badge-menu,
.li-home-list .badge-menu {
    background-color: white;
    color: #02afff;
}

.reseller-dropdown {
    transition: all 0.15s linear;
}

.reseller-dropdown:hover {
    background-color: #02afff;
}

.reseller-dropdown:hover .badge-menu,
.reseller-dropdown.dropdown.open .badge-menu {
    background-color: white;
    color: #02afff;
}

.badge-list {
    background-color: #d2cdc8;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    margin: 1em;
}


/* ####### START Pulsante Reseller ####### */

.Reseller-Status {
    /*margin: 1.1rem 2.4rem 1.1rem 0;*/
    min-width: 6rem;
    max-width: 16rem;
    max-height: 35px;
    transition: transform 0.15s linear, color 0.15s linear;
    border-radius: 6px;
}

.Reseller-Status-Green {
    color: green;
    background-color: #45bf67;
    border: 2px outset #106e2b;
    transition: transform 0.15s linear, color 0.15s linear;
}

.Reseller-Status-Red {
    color: red;
    background-color: #bf456e;
    border: 2px outset #861233;
}

.Reseller-Status-Green>span {
    forced-color-adjust: preserve-parent-color;
    font-weight: bold;
    transition: transform 0.15s linear, color 0.15s linear;
}

.Reseller-Status-Red>span {
    forced-color-adjust: preserve-parent-color;
    font-weight: bold;
    transition: transform 0.15s linear, color 0.15s linear;
}

.Reseller-Status-Green:active,
.Reseller-Status-Red:active,
.Reseller-Status:active,
.Reseller-Status>span:active,
.Reseller-Status-Red>span:active,
.Reseller-Status-Green>span:active,
.Reseller-Status-Green:hover,
.Reseller-Status-Red:hover,
.Reseller-Status:hover,
.Reseller-Status>span:hover,
.Reseller-Status-Red>span:hover,
.Reseller-Status-Green>span:hover {
    color: white;
    transform: scale(1.1);
}

/* ####### END Pulsante Reseller ####### */